* {
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

@font-face {
	font-family: 'QuanRounded';
	font-weight: 300;
	src: url('/wp-content/themes/vrachtbaan/assets/fonts/QuanRounded-SemiLight.woff') format('woff');
}

@font-face {
	font-family: 'QuanRounded';
	font-weight: 500;
	src: url('/wp-content/themes/vrachtbaan/assets/fonts/QuanRounded-Regular.woff') format('woff');
}

@font-face {
	font-family: 'QuanRounded';
	font-weight: 600;
	src: url('/wp-content/themes/vrachtbaan/assets/fonts/QuanRounded-Bold.woff') format('woff');
}

@font-face {
	font-family: 'QuanRounded';
	font-weight: 700;
	src: url('/wp-content/themes/vrachtbaan/assets/fonts/QuanRounded-ExtraBold.woff') format('woff');
}

@font-face {
	font-family: 'QuanRounded';
	font-weight: 900;
	src: url('/wp-content/themes/vrachtbaan/assets/fonts/QuanRounded-Black.woff') format('woff');
}

@font-face {
	font-family: 'Stoneharbour';
	src: url('/wp-content/themes/vrachtbaan/assets/fonts/Stoneharbour-Italic.woff') format('woff');
}

body {
	color: $c-text;
	font-size: 20px;
	line-height: 24px;
	font-weight: 300;
	font-family: QuanRounded, Helvetica, sans-serif;
}

a:link, a:visited, a:active {
	text-decoration: none;
}

// Headers
h1 {
	font-size: 34px;
	line-height: 38px;
	color: #fff;
	font-weight: 700;
	letter-spacing: 1px;
	font-family: QuanRounded, Helvetica, 'sans-serif';
}

h2 {
	font-size: 32px;
	line-height: 31px;
	letter-spacing: 1.5px;
	font-family: QuanRounded, Helvetica, 'sans-serif';
}

h3 {
	font-size: 26px;
	line-height: 26px;
	font-weight: 900;
	letter-spacing: 2px;
	font-family: QuanRounded, Helvetica, 'sans-serif';
}

h4 {
	font-size: 24px;
	line-height: 27px;
	font-weight: 300;
	letter-spacing: 2px;
	font-family: QuanRounded, Helvetica, 'sans-serif';
}


// Quotes
blockquote {
	margin: 20px 0 40px;
	font-size: 30px;
	line-height: 35px;
	color: $c-blue;
	font-family: Helvetica, 'sans-serif';
}


// Bold
strong {
	color: $c-blue;
}


// Icons fonts
.ui {
	font-style: normal;
	font-family: UIFONT, sans-serif;
}

.fo {
	font-style: normal;
	font-family: 'fontello', sans-serif;
}

.fa {
	font-style: normal;
	font-family: FontAwesome, sans-serif;
}