$bp-tablet: 700px;
$bp-desktop: 1100px;
$bp-large-desk: 1160px;

@mixin bp($type) {
	$bp: '';

	@if $type == tablet  {
		$bp: $bp-tablet;
	} @else if $type == desktop {
		$bp: $bp-desktop;
	} @else if $type == large-desk {
		$bp: $bp-large-desk;
	}

	@if $bp != '' {
		@media screen and (min-width: $bp) {
			@content;
		}
	}
}