.references {
	padding: 50px 0;

	&__item {
		display: flex;
		flex-wrap: wrap;
		padding: 20px;

		&:nth-child(even) {
			flex-direction: row-reverse;
			background: $c-border;
		}
	}

	&__figure {
		flex-basis: 100%;
		text-align: center;
		padding-bottom: 40px;
		margin-bottom: 10px;
		background: url('/wp-content/themes/vrachtbaan/assets/images/shadow.png') no-repeat bottom 20px center;
		background-size: 60% auto;

		&__img {
			position: relative;
			display: inline-block;
			width: 115px;
			height: 115px;
			overflow: hidden;
			border-radius: 50%;
			background-size: cover;
		}
	}

	&__content {
		flex-basis: 100%;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;

		&__header {
			flex-basis: 100%;
			margin-bottom: 10px;

			&__title {
				color: $c-blue;
			}

			&__city {
				display: block;
				margin-bottom: 15px;
				font-size: 22px;
				line-height: 23px;
				letter-spacing: 1.5px;
				font-weight: bold;
				font-family: QuanRounded, Helvetica, 'sans-serif';
				color: $c-grey;
			}
		}

		&__rating {
			display: flex;
			justify-content: space-between;

			&__date {
				margin-top: 4px;
				font-size: 14px;
			}
		}
	}



	// BREAKPOINTS
	@include bp(tablet) {
		&__item {
			justify-content: space-between;
			padding: 40px;
		}

		&__figure {
			flex-basis: 25%;
			align-self: flex-start;
			margin-bottom: 0;
			background-position: bottom center;
			background-size: 100% auto;

			&__img {
				width: 175px;
				height: 175px;
			}
		}

		&__content {
			flex-basis: 65%;
		}
	}

	@include bp(desktop) {
		&__item {
			padding: 60px 200px;

			&--in-header {
				padding: 60px 135px;
			}
		}

		&__content {
			flex-basis: 70%;
		}
	}
}
