.figure {
	// Home
	&__truck {
		display: none;
	}

	&__animated-truck {
		position: absolute;
		z-index: 10;
		bottom: 10px;
		left: 50%;
		width: 530px;
		height: 150px;
		background: url('/wp-content/themes/vrachtbaan/assets/images/vrachtwagen.png') center left;
		background-size: cover;
		transform: translate(90vw, 0px);
		transition: transform 1s ease-in-out;

		&--ride-in {
			z-index: 20;
			transform: translate(-50%, 0);
		}
	}

	// Content
	&__usp-shadow {
		max-height: 140px;
		margin: -20px 0 5px;
		background: url('/wp-content/themes/vrachtbaan/assets/images/shadow.png') no-repeat bottom center;
	}

	&__usp-meter {
		margin-bottom: 20px;
		background: url('/wp-content/themes/vrachtbaan/assets/images/gas.png') no-repeat top center;
		background-size: 58% auto;

		&__pointer {
			height: 80px;
			margin-top: 20px;
			transform-origin: bottom center;
		}
	}


	// BREAKPOINTS
	@include bp(tablet) {
		// Home
		&__truck {
			display: block;
			position: absolute;
			z-index: 80;
			top: 0;
			right: 0;
			width: 125px;
			height: 610px;
			background: url('/wp-content/themes/vrachtbaan/assets/images/vrachtwagen-scania.png') no-repeat center left;
			background-size: auto 100%;
			animation: ride-truck-in-tablet 1s;
			animation-fill-mode: forwards;

			&__btn {
				position: absolute;
				bottom: 50px;
				left: 50px;
				width: 50px;
				height: 50px;

				&:after,
				&:before {
					content: '';
					position: absolute;
					overflow: hidden;
					border-radius: 50%;
				}

				&:before {
					left: 10px;
					top: 10px;
					width: 30px;
					height: 30px;
					background: #fff;
				}

				&:after {
					left: 0;
					top: 0;
					width: 50px;
					height: 50px;
					border: 2px solid #fff;
				}
			}

			&__stapin {
				display: none;
			}
		}

		&__animated-truck {
			width: 1110px;
			height: 270px;
		}


		// Content
		&__usp-meter {
			background-size: 64% auto;
		}
	}


	@include bp(desktop) {
		// Home
		&__truck {
			top: -140px;
			right: 0;
			width: 350px;
			height: 940px;
			animation: ride-truck-in-desktop 1s;

			&__btn {
				bottom: 100px;
				left: 100px;
				cursor: pointer;

				&:before {
					left: 5px;
					top: 5px;
					width: 34px;
					height: 34px;
				}

				&:after {
					width: 44px;
					height: 44px;
				}

				&:hover {
					left: 95px;

					&:before {
						width: 40px;
						height: 40px;
					}

					&:after {
						width: 50px;
						height: 50px;
					}
				}
			}

			&__stapin {
				display: block;
				position: absolute;
				bottom: 0;
				left: -380px;
				width: 470px;
				height: auto;
				max-width: none;
			}
		}

		&__animated-truck {
			height: 300px;

			&--step2 {
				transform: translate(-250px, 0);
			}
		}

		// Content
		&__usp-meter {
			background-size: 75% auto;
		}
	}
}