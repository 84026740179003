.repeatable-blocks {
  margin: 40px 0;
  padding: 0 20px;

  @include bp(desktop) {
    margin: 60px 0;
    padding: 0;
  }

  &--blue {
    padding: 40px 20px;
    margin: 0;
    background: $c-blue;

    @include bp(desktop) {
      padding: 60px 0;
      margin: 0;
    }

    .repeatable-blocks {
      &__subtitle {
        color: #fff;
      }

      &__item {
        color: $c-text;
        background: #fff;
      }

      &__item-title {
        color: #666;
      }
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;

    > .repeatable-blocks__title {
      flex-basis: 100%;
      padding: 0;
      margin-bottom: 10px;
      font-size: 40px;
      color: $c-grey;
      text-align: center;
    }
  }

  &__subtitle {
    flex-basis: 100%;
    color: $c-blue;
    margin-bottom: 25px;
    text-align: center;
  }

  &__wrapper {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    flex-basis: 100%;
    margin-bottom: 20px;
    padding: 20px;
    color: #fff;
    background: $c-blue;

    @include bp(tablet) {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      flex-basis: 48%;
      margin: 0 20px 20px 0;

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }

    @include bp(desktop) {
      flex: 1;
      flex-basis: 20%;
      margin: 0 20px 0 0 !important;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__btn {
    width: 100%;
  }
}