.footer {
	padding: 50px 23px;
	color: #fff;
	text-align: center;
	background: $c-grey;

	.container {
		flex-direction: column;
	}

	&__logo {
		display: block;
		margin-bottom: 35px;
	}

	&__txt {
		display: block;
		padding-left: 65px;
		text-align: left;

		&__address {
			font-style: normal;
		}
	}

	&__list {
		margin: 20px 0;

		&__item {
			display: block;

			a {
				color: #fff;
			}

			&:first-child {
				color: #fff;
			}
		}
	}

	&__cta {
		font-size: 20px;
		text-align: center;
	}


	// BREAKPOINTS
	@include bp(tablet) {
		display: flex;
		padding: 50px 30px;

		.container {
			flex-direction: row;
		}

		&__logo {
			flex-basis: 45%;
			text-align: left;
		}

		&__txt {
			display: flex;
			flex-basis: 65%;
			flex-wrap: wrap;
			padding-left: 0;
		}

		&__list {
			margin: 0 0 30px;
			flex-basis: 50%;
		}

		&__cta {
			flex-basis: 40%;
		}
	}

	@include bp(desktop) {
		&__logo {
			flex-basis: 30%;
			padding-top: 22px;
		}

		&__txt {
			flex-wrap: nowrap;
			flex-basis: 70%;
			align-items: center;
		}

		&__list {
			margin-bottom: 0;

			&__item a:hover {
				color: $c-blue;
			}
		}

		&__cta {
			flex-basis: 60%;
		}
	}
}