.header {
	position: relative;
  padding: 25px 0 20px;
  background: #ffffff;

	.container {
		justify-content: space-between;
	}

	&__toggle {
		width: 27px;
		margin-top: 10px;

		&__bar {
			display: block;
			height: 5px;
			width: 100%;
			margin-bottom: 3px;
			background: $c-blue;
			border-radius: 5px;
			transition: transform .2s ease-out;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&--active {
			position: relative;

			.header__toggle__bar {
				position: absolute;
				top: 9px;

				&:nth-child(1) {
					transform: rotate(45deg);
				}

				&:nth-child(2) {
					display: none;
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
				}
			}
		}
	}

	&__menu {
		position: relative;
		display: none;

		&--active {
			display: block;
			position: absolute;
			z-index: 100;
			top: 95px;
			left: 0;
			width: 100%;
			background: #fff;
		}

		&:after {
			content: "";
			position: absolute;
			z-index: 10;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			background: rgba(0, 0, 0, .7);
		}

		&__list {
			position: relative;
			z-index: 20;
			width: 100%;
			padding: 0 23px;
			background: #fff;

			&__item {
				display: block;
				text-align: center;
				font-weight: 600;
				border-top: 1px solid $c-border;

				&--subitem {
					font-weight: 300;
				}
			}
		}

		&__link {
			display: block;
			width: 100%;
			padding: 15px 0 10px;
			color: $c-blue;
		}
  }

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 120;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  }

	// BREAKPOINTS
	@include bp(tablet) {
		padding: 15px 0 10px;

		&__toggle {
			margin-top: 12px;
		}

		&__menu {
			&--active {
				top: 75px;
			}
		}
	}

	@include bp(desktop) {
		padding: 50px 0 15px;

		.container {
			justify-content: flex-start;
		}

		&__toggle {
			display: none;
		}

		&__menu {
			display: flex;
			position: static;
			padding-left: 50px;

			&:after {
				display: none;
			}

			&__list {
				display: flex;
				padding: 0;
				margin-right: 20px;

				&:first-of-type {
					margin-left: 0;
				}

				&__item {
					margin-right: 20px;
					border-top: 0;
					white-space: nowrap;

					&:last-child {
						margin-right: 0;
					}

					&--subitem {
						order: 2;
						margin: 0;
						font-size: 17px;

						&--tel {
							order: 0;

							a {
								color: $c-light;
							}
						}

						&--mail {
							order: 1;
						}
					}
				}

				&--sublist {
					display: flex;
					justify-content: flex-end;
					position: absolute;
					z-index: 100;
					top: 0;
					left: 50%;
					max-width: 1200px;
					padding-right: 50px;
					background: #ebecec;
					transform: translate(-50%, 0);

					&:after {
						content: '';
						position: absolute;
						z-index: -1;
						top: 0;
						right: -100vw;
						height: 100%;
						width: 200vw;
						background: $c-border;
					}
				}
			}

			&__link {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					top: 40px;
					left: 50%;
					width: 0;
					height: 3px;
					background: $c-light;
					border-radius: 5px;
					opacity: 0;
					transform: translate(-50%, 0);
					transition: width .3s, opacity .3s;
				}

				&:hover:after,
				&--active:after {
					opacity: 1;
					width: 50%;
				}

				&--sublink {
					padding: 5px 10px 4px;

					&:after {
						display: none;
					}

					&:hover {
						background: #f2f3f3;
					}
				}
			}
		}
  }

  &__reviews {
    display: none;

    @include bp(tablet) {
      display: block;
      position: absolute;
      z-index: 110;
      top: 7px;
      left: 0;
      width: 50%;
      max-width: 800px;
      padding-left: 34px;

      span {
        width: 217px;
        display: inline-block;
        position: relative;
        margin-top: -10px;
      }
    }
  }
}