.content {
  ul {
    margin: -20px 0 20px 20px;
  }

  .container {
    flex-direction: column;
    position: relative;
  }

  .container,
  &.container {
    padding: 0;
  }

  // Content elems
  &__figure {
    width: 100%;
    min-height: 130px;

    &__img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
    }

    &--inline {
      text-align: center;
    }
  }

  &__title {
    display: block;
    margin-bottom: 20px;
  }

  > .content__title {
    padding: 30px 23px 0;
  }

  &__txt {
    position: relative;
    padding: 60px 23px 25px;
    color: #fff;
    background: $c-blue;

    &--video {
      background: $c-border;
    }

    .btn--white {
      &:hover {
        background-color: $c-border;
      }
    }
  }

  &__form {
    margin-top: 30px;
  }

  // White bg, blue text
  &--white {
    .content__title {
      color: $c-blue;
    }

    .content__txt {
      padding: 40px 23px;
      color: $c-text;
      background: #fff;

      &--intro {
        color: $c-blue;

        .content__title {
          text-align: center;
        }
      }
    }
  }

  // Columns blue and white
  &--columns {
    padding: 40px 0;

    .content__txt {
      padding: 25px 23px;

      &--video {
        padding: 25px 23px;
      }

      &:not(.content__txt--video) + .content__txt {
        padding: 0 23px 25px;
      }
    }

    &:not(.content--blue) .content__txt {
      color: $c-text;
    }
  }

  // BREAKPOINTS
  @include bp(tablet) {
    display: flex;

    .container:not(.container--column) {
      flex-direction: row;
      padding: 0;
    }

    &__figure {
      order: 10;
      width: 35%;
      //flex-basis: 35%;
      position: relative;
      min-height: 100%;

      &__img {
        width: 50vw;
      }
    }

    &__txt {
      width: 65%;
      //flex-basis: 65%;
      padding: 50px 50px 50px 80px;

      &:before {
        content: '';
        position: absolute;
        z-index: -2;
        left: -50vw;
        top: 0;
        width: 150vw;
        height: 100%;
        background: inherit;
      }

      &--video {
        padding: 30px;
        background: $c-border;
      }

      &--stretch {
        flex-basis: 100%;

        &:before {
          display: none;
        }
      }
    }

    // White bg, blue text
    &--white .content__txt {
      padding: 50px 65px;

      &--intro {
        flex-basis: 100%;

        .content__title {
          font-size: 40px;
        }
      }
    }

    // Columns blue and white
    &--columns.content--blue .content__txt {
      width: 49%;
      //flex-basis: 49%;
      padding: 30px;
    }

    &--columns.content--white.container {
      padding: 50px 45px;

      .content__txt {
        width: 49%;
        //flex-basis: 49%;
        padding: 0 20px;
      }
    }
  }

  @include bp(desktop) {
    .container {
      overflow: visible;
    }

    &--first-order {
      order: 10;

      .content__figure__img {
        left: auto;
        right: 0;
      }
    }

    &--last-order {
      order: 20;
    }

    &__title {
      font-size: 27px;
      line-height: 28px;
    }

    &__figure {
      width: 50%;
      //flex-basis: 50%;

      &--inline {
        padding: 30px 0;
        text-align: center;

        + .content__txt {
          align-self: center;
        }
      }
    }

    &__txt {
      width: 50%;
      //flex-basis: 50%;
      min-height: 350px;

      &--video {
        &:before {
          z-index: -1;
          left: -100vw;
        }

        &.content--last-order:before {
          left: 0vw;
        }
      }

      &--stretch {
        flex-basis: 100%;
        min-height: 0;
        padding: 50px 100px;
        margin-bottom: 100px;

        .content__title {
          text-align: center;
          font-size: 30px;
        }
      }
    }

    // White bg, blue text
    &--white .content__txt {
      min-height: 0;

      &--intro {
        padding: 50px 240px;

        .content__title {
          font-size: 40px;
        }
      }
    }

    // Columns blue and white
    &--columns > .content__title {
      padding: 0 23px;
      flex-basis: 100%;
    }
  }
}